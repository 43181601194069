import React from 'react';

function Footer() {
  return (
    <footer className="footer" id="footer">
      <p className="footer-text" id="footerText">© 2024 elevere labs.</p>
      <p className="footer-text" id="footerText">The trusted talent network.</p>
    </footer>
  );
}

export default Footer;
