import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for programmatic navigation

const TopBanner = ({ onClose }) => {
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Handler for the close button to stop event propagation and close the banner
  const handleCloseClick = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div className="top-banner" onClick={() => navigate('/contact')}>
        <div className="banner-text-container">
            🚀  We're hiring &nbsp;
            {/* Removed Link component to simplify, as the entire banner navigates now */}
            <span className="learn-more-text"><strong>Learn more </strong></span>
        </div>
      <button onClick={handleCloseClick} className="close-banner">×</button>
    </div>
  );
};

export default TopBanner;
