import React from 'react';
import featureImage2 from '../images/notifications-invitations.png';


const GotACodeFeatureSection2 = () => {
  return (
    <div className="image-text-section">
      <div className="section-image">
        <img src = {featureImage2} alt="" />
      </div>
      <div className="section-text">
        <h2>Get Invited</h2>
        <p>Each month, users are empowered with two invites to extend to high performing peers they endorse</p>
        <a href="/faq" className="secret-code-link">
          <button className='feature-section-two-learn-more-button'>Learn More</button>
        </a>
      </div>
    </div>
  );
};

export default GotACodeFeatureSection2;
