import React from 'react';
import featureImageMainBody2 from '../images/do-you-know-someone.png';


const MainBodyFeatureSection2 = () => {
    return (
        <div className="image-text-section">
          <div className="section-image">
            <img src = {featureImageMainBody2} alt=""/>
          </div>
          <div className="section-text">
            <h2>"Do you know someone...?"</h2>
            <p>Ever asked a trusted peer to tap into their network to recommend someone?</p>
            <p>elevere's unique platform enables users to leverage the trusted networks of it's global community</p>
          </div>
        </div>
    );
  };

export default MainBodyFeatureSection2;
