import React from 'react';
import featureImageMainBody1 from '../images/network-users-feature-image-light.png';


const MainBodyFeatureSection = () => {
    return (
        <div className="image-text-section">
          <div className="section-image">
            <img src = {featureImageMainBody1} alt=""/>
          </div>
          <div className="section-text">
            <h2>Network with confidence</h2>
            <p>Our high-trust community that prioritises verification and user safety means that you can confidently explore opportunities and engage with other users</p>
          </div>
        </div>
    );
  };

export default MainBodyFeatureSection;
