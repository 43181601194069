import React from 'react';

function Contact() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <section>
        <h2>General</h2>
        <p><a href="mailto:hello@elvrlabs.com">hello@elvrlabs.com</a></p>
      </section>
      <section>
        <h2>Explore open roles</h2>
        <p><a href="mailto:careers@elvrlabs.com">careers@elvrlabs.com</a></p>
      </section>
      <section>
        <h2>Investors</h2>
        <p><a href="mailto:ir@elvrlabs.com">ir@elvrlabs.com</a></p>
      </section>
    </div>
  );
}

export default Contact;