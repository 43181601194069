import React from 'react';
import featureImage1 from '../images/network-users-feature-image.png';


const GotACodeFeatureSection1 = () => {
  return (
      <div className="image-text-section">
        <div className="section-image">
          <img src = {featureImage1} alt=""/>
        </div>
        <div className="section-text">
          <h2>Gain Access to a High-trust Talent Network</h2>
          <p>elevere is where leading professionals connect and top organisations seek talent. Our platform gives every user confidence in their interactions, providing access to trusted networks that extend far beyond our own</p>
        </div>
      </div>
  );
};

export default GotACodeFeatureSection1;
