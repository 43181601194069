import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from "firebase/firestore";
import firebaseExports from '../firebase-config';

const { app } = firebaseExports;

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('');

  // Initialize Firestore database
  const db = getFirestore(app);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, "submissions"), {
        email: email,
        timestamp: new Date(),
      });
      setEmail(''); // Clear the input box after successful signup
      setBannerMessage("You've joined the waitlist!");
      setShowBanner(true);
      setTimeout(() => setShowBanner(false), 15000); // Hide banner after 15 seconds
    } catch (e) {
      console.error("Error adding document: ", e);
      setBannerMessage("Oops! Something went wrong. Try again or contact our team");
      setShowBanner(true);
      setTimeout(() => setShowBanner(false), 15000);
    }
  };

  return (
    <>
      {showBanner && (
        <div className="signup-banner">
          {bannerMessage}
          <button onClick={() => setShowBanner(false)} className="banner-close">X</button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="signup-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="email-input"
          placeholder="Enter your email"
          required
        />
        <button type="submit" className="submit-button">Join Waitlist</button>
      </form>
    </>
  );
};

export default SignupForm;
