import React, { useState, useEffect } from 'react';

const SecretCodeLink = () => {
    // State to control the visibility of the button
    const [isVisible, setIsVisible] = useState(false);

    // Effect to handle the timing of the button appearance
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);  // Make the button visible after 1 second
        }, 1250); // 1000 milliseconds = 1 second

        return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }, []); // Empty dependency array means this effect runs only once after the initial render

    return (
        <a href="/gotacode" className="secret-code-link">
            {isVisible && (  // Only render the button if isVisible is true
                <button className="secret-code-button">
                    Got a secret code?
                </button>
            )}
        </a>
    );
};

export default SecretCodeLink;
