import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import MainBody from './components/MainBody';
import TopBanner from './components/TopBanner'; // Ensure this import is correct
import Contact from './components/Contact'; // Ensure this import is correct
import './App.css';
import GotACodePage from './components/GotACodePage';
import FAQ from './components/FAQSection';

function App() {
  const [isBannerVisible, setIsBannerVisible] = useState(true); // State to control banner visibility

  return (
    <Router>
      <div className="App">
        {isBannerVisible && <TopBanner onClose={() => setIsBannerVisible(false)} />}
        <Header />
        <Routes>
          <Route path="/" element={<MainBody />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gotacode" element={<GotACodePage />} />
          <Route path="/faq" element={<FAQ />} />
          {/* For additional pages, add Route components here */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
