import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/elevere_070707.png';

function Header() {
  return (
    <header className="header" id="header">
      <div className="logo-container">
      <Link to="/">
        <img src={logo} alt="Logo" className="logo" />
      </Link >
      </div>
      <nav>
        <ul className="nav-links" id="navLinks">
          <li><Link to="/" className="nav-link" id="homeLink">Home</Link></li>
          <li><Link to="/contact" className="nav-link" id="aboutLink">Contact</Link></li>
          <li><Link to="/gotacode" className="nav-link" id="aboutLink">Enter code</Link></li>
          {/* Add more navigation links as needed */}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
