import React from 'react';
import SeccretCodeLink from './SecretCodeLink';
import GotACodeFeatureSection1 from './GotACodeFeatureSection1';
import GotACodeFeatureSection2 from './GotACodeFeatureSection2';

function GotACodePage() {
  return (
    <div className="main-body">
      <div className="content">
        <h1 className="main-header">Got an invite?</h1>
        <p className="sub-header">Join elevere with your invitation code.</p>
        <a href="https://invitation.elvrlabs.com/enter-invitation/" className="secret-code-link">
            <button className="enter-code-button">Enter Code</button>
        </a>
        <GotACodeFeatureSection1 />
        <GotACodeFeatureSection2 />
        <SeccretCodeLink />
      </div>
    </div>
  );
}

export default GotACodePage;
