import React from 'react';
import SignupForm from './SignupForm';
import SeccretCodeLink from './SecretCodeLink';
import MainBodyFeatureSection from './MainBodyFeatureSection';
import MainBodyFeatureSection2 from './MainBodyFeatureSection2';

function MainBody() {
  return (
    <div className="main-body">
      <div className="content">
        <h1 className="main-header">The trusted talent network.</h1>
        <p className="sub-header">A community of verified top performers and professionals...</p>
        <SignupForm />
        <SeccretCodeLink />
        <h2 className='main-body-h2'>elevere provides access to trusted networks that extend far beyond our own</h2>
        <MainBodyFeatureSection2 />
        <MainBodyFeatureSection />
      </div>
    </div>
  );
}

export default MainBody;
